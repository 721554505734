<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Arama"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="users"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :sort-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.username }}</span>
        </span>
        <span
          v-else-if="props.column.field === 'requests'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.requests }}</span>
        </span>
        <span
          v-else-if="props.column.field === 'seconds'"
          class="text-nowrap"
        >
          <span class="text-nowrap">
            <span v-if="props.row.duration.days > 0">{{ props.row.duration.days }} Gün </span>
            <span v-if="props.row.duration.hours > 0">{{ props.row.duration.hours }} Saat </span>
            <span v-if="props.row.duration.minutes > 0">{{ props.row.duration.minutes }} Dakika </span>
            <span v-if="props.row.duration.seconds > 0 && props.row.duration.minutes == 0">{{ props.row.duration.seconds }} Saniye</span>
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10','25','50','100']"
              class="mr-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> Toplam Satır: {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Danışman',
          field: 'username',
          thClass: 'font-small-3',
          tdClass: 'font-small-3',
        },
        {
          label: 'Başvuru Adedi',
          field: 'requests',
          width: '200px',
          thClass: 'font-small-3 text-center',
          tdClass: 'font-small-3 text-center',
        },
        {
          label: 'Ortalama Çözüm Yanıtı Süresi',
          field: 'seconds',
          width: '250px',
          thClass: 'font-small-3',
          tdClass: 'font-small-3',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    users() {
      const data = this.$store.getters['requestsReport/getSummary']
      let usersSolutionDurations = []
      if (data) {
        usersSolutionDurations = data.usersSolutionDurations
      }
      return usersSolutionDurations
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
