<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
  },
  props: [
    'dataLabels',
    'dataSeries',
    'height',
  ],
  data() {
    return {
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: $themeColors.info,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
    }
  },
  watch: {
    dataSeries(val) {
      if (val) {
        this.setLabels()
      }
    },
  },
  created() {
    this.setLabels()
  },
  methods: {
    setLabels() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.dataLabels,
          },
        },
      }
      this.series = [this.dataSeries]
    },
  },
}
</script>
