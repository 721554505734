<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          title="Başvuru Raporu"
        >
          <b-card-text>
            <b-row>
              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Başvuru Konusu">
                  <v-select
                    v-model="id_com_crm_subject"
                    label="title"
                    :options="crmSubjects"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="crmSubject => crmSubject.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Başvuru Tipi">
                  <v-select
                    v-model="id_com_feedback_type"
                    label="title"
                    :options="feedbackTypes"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="feedbackType => feedbackType.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Başvuru Durumu">
                  <v-select
                    v-model="id_com_crm_status"
                    label="title"
                    :options="crmStatuses"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="crmStatus => crmStatus.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Başvuru Kaynağı">
                  <v-select
                    v-model="id_com_crm_applysource"
                    label="title"
                    :options="crmApplySources"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="crmApplySource => crmApplySource.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Departman">
                  <v-select
                    v-model="id_com_department"
                    label="title"
                    :options="departments"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="department => department.id"
                    multiple
                  />
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                md="4"
              >
                <b-form-group label="Önem Derecesi">
                  <v-select
                    v-model="id_com_crm_level"
                    label="title"
                    :options="crmLevels"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="crmLevel => crmLevel.id"
                    multiple
                  />
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Marka">
                  <v-select
                    v-model="id_com_brand"
                    label="title"
                    :options="brands"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="brand => brand.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Lokasyon">
                  <v-select
                    v-model="id_com_location"
                    label="title"
                    :options="locations"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="location => location.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Başlangıç Tarihi">
                  <b-form-datepicker
                    v-model="sdate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Bitiş Tarihi">
                  <b-form-datepicker
                    v-model="edate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="text-center mt-1"
              >
                <b-button
                  variant="primary"
                  @click="getSummary"
                >
                  <FeatherIcon icon="BarChartIcon" />
                  <span class="align-middle">Raporu Görüntüle</span>
                </b-button>
                <b-button
                  v-if="summary.requests.length > 0 && !canvasImageStatus"
                  v-b-modal.modal-pdf
                  variant="danger"
                  class="ml-1"
                  @click="submitPdfImage"
                >
                  <FeatherIcon icon="FileTextIcon" />
                  <span class="align-middle">PDF OLUŞTUR</span>
                </b-button>
                <b-button
                  v-else-if="canvasImageStatus && !exportPdfFile.status"
                  variant="danger"
                  class="ml-1"
                  disabled
                >
                  <b-spinner small />
                  Lütfen Bekleyiniz...
                </b-button>
                <b-button
                  v-if="exportPdfFile.status"
                  variant="success"
                  class="ml-1"
                  :href="downloadURL"
                  target="_blank"
                  @click="resetPdfData"
                >
                  <FeatherIcon icon="DownloadIcon" />
                  <span class="align-middle">PDF İNDİR</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <Loading />
      </b-col>
    </b-row>
    <b-row
      v-if="summary.requests.length > 0"
    >
      <b-col
        xs="12"
        md="12"
      >
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <b-card-title>Tüm Başvurular</b-card-title>
              <b-card-sub-title>Başvuru Durumları</b-card-sub-title>
            </b-col>
            <b-col
              cols="auto"
              class="text-right"
            >
              <b-form-checkbox
                v-model="exportData.summary"
                v-b-tooltip.hover.left.v-primary
                title="PDF'e Ekle"
                class="custom-control-primary"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-card-text id="summary">
            <b-row>
              <b-col
                v-for="(request,key) in summary.requests"
                :key="key"
              >
                <div class="text-center">
                  <h3 class="text-primary font-weight-light">
                    {{ request.title }}
                  </h3>
                  <h1 class="font-weight-bolder">
                    {{ request.requests }}
                  </h1>
                </div>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col>
                <div class="text-center">
                  <h3 class="text-primary font-weight-light">
                    İlk Yanıt Ortalaması
                  </h3>
                  <h2 class="font-weight-bolder">
                    <span v-if="summary.answerDurations.days > 0">{{ summary.answerDurations.days }} Gün </span>
                    <span v-if="summary.answerDurations.hours > 0">{{ summary.answerDurations.hours }} Saat </span>
                    <span v-if="summary.answerDurations.minutes > 0">{{ summary.answerDurations.minutes }} Dakika </span>
                    <span v-if="summary.answerDurations.seconds > 0 && summary.answerDurations.minutes == 0">{{ summary.answerDurations.seconds }} Saniye</span>
                  </h2>
                </div>
              </b-col>
              <b-col>
                <div class="text-center">
                  <h3 class="text-primary font-weight-light">
                    Çözüm Yanıtı Ortalaması
                  </h3>
                  <h1 class="font-weight-bolder">
                    <span v-if="summary.solutionDurations.days > 0">{{ summary.solutionDurations.days }} Gün </span>
                    <span v-if="summary.solutionDurations.hours > 0">{{ summary.solutionDurations.hours }} Saat </span>
                    <span v-if="summary.solutionDurations.minutes > 0">{{ summary.solutionDurations.minutes }} Dakika </span>
                    <span v-if="summary.solutionDurations.seconds > 0 && summary.solutionDurations.minutes == 0">{{ summary.solutionDurations.seconds }} Saniye</span>
                  </h1>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xs="12"
        md="12"
      >
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <b-card-title>Başvuru Konuları</b-card-title>
              <b-card-sub-title>En Çok Başvuru Alan 10 Konu</b-card-sub-title>
            </b-col>
            <b-col
              cols="auto"
              class="text-right"
            >
              <b-form-checkbox
                v-model="exportData.subjects"
                v-b-tooltip.hover.left.v-primary
                title="PDF'e Ekle"
                class="custom-control-primary"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-card-text id="subjects">
            <b-row>
              <b-col
                xs="12"
                md="7"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(subject,key) in summary.subjects"
                    :key="key"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>{{ subject.title }}</span>
                    <b-badge
                      variant="primary"
                      pill
                      class="badge-round"
                    >
                      {{ subject.count }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col
                xs="12"
                md="5"
              >
                <apex-donut-chart />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xs="12"
        md="12"
      >
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <b-card-title>Başvuru Tipleri</b-card-title>
              <b-card-sub-title>En Çok Başvuru Alan 10 Tip</b-card-sub-title>
            </b-col>
            <b-col
              cols="auto"
              class="text-right"
            >
              <b-form-checkbox
                v-model="exportData.types"
                v-b-tooltip.hover.left.v-primary
                title="PDF'e Ekle"
                class="custom-control-primary"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-card-text id="types">
            <b-row>
              <b-col
                xs="12"
                md="6"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(requestType,key) in summary.requestTypes"
                    :key="key"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>{{ requestType.title }}</span>
                    <b-badge
                      variant="primary"
                      pill
                      class="badge-round"
                    >
                      {{ requestType.count }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <apex-bar-chart
                  v-if="summary.requestTypesChart.labels.length > 0"
                  :data-labels="summary.requestTypesChart.labels"
                  :data-series="summary.requestTypesChart.series"
                  :height="summary.requestTypesChart.height"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xs="12"
        md="12"
      >
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <b-card-title>Çözüm Yanıt Ortalaması</b-card-title>
              <b-card-sub-title>Danışman Bazlı</b-card-sub-title>
            </b-col>
            <b-col
              cols="auto"
              class="text-right"
            >
              <b-form-checkbox
                v-model="exportData.usersSolutionDurations"
                v-b-tooltip.hover.left.v-primary
                title="PDF'e Ekle"
                class="custom-control-primary"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeOffIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div id="usersSolutionDurations">
            <users-solution-durations />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card
          title="Yanıt Süreleri"
          sub-title="Detaylı Yanıt Süreleri"
        >
          <b-card-text>
            <b-row>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Marka">
                  <v-select
                    v-model="durationFilter.id_com_brand"
                    label="title"
                    :options="brands"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="brand => brand.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Departman">
                  <v-select
                    v-model="durationFilter.id_com_department"
                    label="title"
                    :options="departments"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="department => department.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Lokasyon">
                  <v-select
                    v-model="durationFilter.id_com_location"
                    label="title"
                    :options="locations"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="location => location.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Başvuru Tipi">
                  <v-select
                    v-model="durationFilter.id_com_feedback_type"
                    label="title"
                    :options="feedbackTypes"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="feedbackType => feedbackType.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="12"
              >
                <b-form-group label="Başvuru Kaynağı">
                  <v-select
                    v-model="durationFilter.id_com_crm_applysource"
                    label="title"
                    :options="crmApplySources"
                    placeholder="Seçiniz"
                    :close-on-select="false"
                    :reduce="crmApplySource => crmApplySource.id"
                    multiple
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Başlangıç Tarihi">
                  <b-form-datepicker
                    v-model="durationFilter.sdate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="Bitiş Tarihi">
                  <b-form-datepicker
                    v-model="durationFilter.edate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="text-center"
              >
                <b-button
                  variant="primary"
                  @click="getDurationDetails"
                >
                  <FeatherIcon icon="BarChartIcon" />
                  <span class="align-middle">Raporu Görüntüle</span>
                </b-button>
                <b-button
                  v-if="durationDetails[0]"
                  :href="downloadURLDurations"
                  variant="success"
                  class="ml-1"
                  target="_blank"
                >
                  <FeatherIcon icon="DownloadIcon" />
                  <span class="align-middle">PDF İndir</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        v-if="durationLoading"
        cols="12"
      >
        <Loading />
      </b-col>
      <b-col cols="12">
        <b-card
          v-for="(durationDetail,key) in durationDetails"
          :key="key"
          no-body
        >
          <b-card-header>
            <b-card-title>{{ durationDetail.title }}</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <span class="font-weight-bold">Toplam Başvuru:</span>
              <span class="text-primary"> {{ durationDetail.requests }} Adet</span>
            </b-card-text>
          </b-card-header>
          <div
            v-for="(brand,bkey) in durationDetail.brands"
            :key="bkey"
          >
            <b-table-simple
              hover
              small
              caption-top
              responsive
            >
              <colgroup><col><col></colgroup>
              <colgroup><col><col><col></colgroup>
              <colgroup><col><col></colgroup>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>
                    {{ brand.title }}
                  </b-th>
                  <b-th
                    width="300"
                    class="text-center"
                  >
                    İlk Yanıt Ortalaması
                  </b-th>
                  <b-th
                    width="300"
                    class="text-center"
                  >
                    Çözüm Yanıtı Ortalaması
                  </b-th>
                  <b-th
                    width="50"
                    class="text-center"
                  >
                    <FeatherIcon icon="EyeIcon" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(department,dkey) in brand.departments">
                  <b-tr :key="dkey">
                    <b-th>
                      <div class="text-primary">
                        {{ department.title }}
                      </div>
                      <div class="text-warning font-weight-light font-small-3">
                        Başvuru: {{ department.requests }} Adet
                      </div>
                    </b-th>
                    <b-td class="text-center">
                      <span v-if="department.answerDurations.days > 0">{{ department.answerDurations.days }} Gün </span>
                      <span v-if="department.answerDurations.hours > 0">{{ department.answerDurations.hours }} Saat </span>
                      <span v-if="department.answerDurations.minutes > 0">{{ department.answerDurations.minutes }} Dakika </span>
                      <span v-if="department.answerDurations.seconds > 0 && department.answerDurations.minutes == 0">{{ department.answerDurations.seconds }} Saniye</span>
                    </b-td>
                    <b-td class="text-center">
                      <span v-if="department.solutionDurations.days > 0">{{ department.solutionDurations.days }} Gün </span>
                      <span v-if="department.solutionDurations.hours > 0">{{ department.solutionDurations.hours }} Saat </span>
                      <span v-if="department.solutionDurations.minutes > 0">{{ department.solutionDurations.minutes }} Dakika </span>
                      <span v-if="department.solutionDurations.seconds > 0 && department.solutionDurations.minutes == 0">{{ department.solutionDurations.seconds }} Saniye</span>
                    </b-td>
                    <b-th class="text-center">
                      <b-button
                        v-b-tooltip.hover.left.v-primary="'Atanan Yetkili Detayı'"
                        variant="flat-primary"
                        pill
                        class="btn-icon"
                        @click="showUsersDetail(key,bkey,dkey)"
                      >
                        <FeatherIcon icon="EyeIcon" />
                      </b-button>
                    </b-th>
                  </b-tr>
                  <template v-if="department.usersDetail">
                    <b-tr
                      v-if="department.usersSolutionDurations"
                      :key="'d_' + dkey"
                    >
                      <b-th colspan="4">
                        <div class="text-info">
                          Atanan Yetkili <small>(İlk 5 Yetkili)</small>
                        </div>
                      </b-th>
                    </b-tr>
                    <b-tr
                      v-for="(user,ukey) in department.usersSolutionDurations"
                      :key="'user_' + ukey"
                    >
                      <b-td colspan="2">
                        <div class="text-danger">
                          {{ user.username }}
                        </div>
                        <div
                          class="text-muted font-weight-light font-small-3"
                        >
                          Başvuru Adet: {{ user.requests }}
                        </div>
                      </b-td>
                      <b-td
                        class="text-center"
                        colspan="2"
                      >
                        <span v-if="user.duration.days > 0">{{ user.duration.days }} Gün </span>
                        <span v-if="user.duration.hours > 0">{{ user.duration.hours }} Saat </span>
                        <span v-if="user.duration.minutes > 0">{{ user.duration.minutes }} Dakika </span>
                        <span v-if="user.duration.seconds > 0 && user.duration.minutes == 0">{{ user.duration.seconds }} Saniye</span>
                      </b-td>
                    </b-tr>
                  </template>
                </template>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td
                    colspan="4"
                    class="text-left"
                  >
                    <div class="mb-0 pb-0">
                      Genel Toplam: <b>{{ brand.requests }}</b>
                    </div>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BBadge,
  BListGroup,
  BListGroupItem,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BTfoot,
  BFormCheckbox,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import html2canvas from 'html2canvas'
// eslint-disable-next-line import/extensions
import ApexDonutChart from '@/views/Reports/apex-chart/ApexDonutChart'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'
// eslint-disable-next-line import/extensions
import ApexBarChart from '@/views/Reports/apex-chart/ApexBarChart'
// eslint-disable-next-line import/extensions
import UsersSolutionDurations from '@/views/Reports/Crm/components/UsersSolutionDurations'

export default {
  name: 'Requests',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    vSelect,
    ApexDonutChart,
    BBadge,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BFormCheckbox,
    BSpinner,
    Loading,
    ApexBarChart,
    UsersSolutionDurations,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id_com_crm_subject: null,
      id_com_department: null,
      id_com_feedback_type: null,
      id_com_crm_level: null,
      id_com_crm_status: null,
      id_com_crm_applysource: null,
      id_com_brand: null,
      id_com_location: null,
      sdate: null,
      edate: null,
      durationFilter: {
        id_com_brand: null,
        id_com_department: null,
        id_com_location: null,
        id_com_feedback_type: null,
        id_com_crm_applysource: null,
        sdate: null,
        edate: null,
      },
      loading: null,
      durationLoading: null,
      exportData: {
        summary: true,
        subjects: true,
        types: true,
        usersSolutionDurations: true,
      },
      canvasImages: {
        summary: null,
        subjects: null,
        types: null,
        usersSolutionDurations: null,
      },
      canvasImageStatus: false,
    }
  },
  computed: {
    crmSubjects() {
      return this.$store.getters['crmSubjects/getCrm_subjects']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    feedbackTypes() {
      return this.$store.getters['feedbackTypes/getFeedback_types']
    },
    crmLevels() {
      return this.$store.getters['crmLevels/getCrm_levels']
    },
    crmStatuses() {
      return this.$store.getters['crmStatuses/getCrm_statuses']
    },
    crmApplySources() {
      return this.$store.getters['crmApplySources/getCrm_applysources']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    summary() {
      const data = this.$store.getters['requestsReport/getSummary']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
    durationDetails() {
      const data = this.$store.getters['requestsReport/getDetailDurations']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.durationLoading = false
      }
      return data
    },
    exportPdfFile() {
      return this.$store.getters['requestsReport/getExportPdfFile']
    },
    downloadURL() {
      const urlParams = [
        `&dir=${this.exportPdfFile.folder}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/reports/crm/requests/pdf?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
    downloadURLDurations() {
      const urlParams2 = []
      if (this.durationFilter.sdate) {
        urlParams2.push(`sdate=${this.durationFilter.sdate}`)
      }
      if (this.durationFilter.edate) {
        urlParams2.push(`edate=${this.durationFilter.edate}`)
      }
      if (this.durationFilter.id_com_brand) {
        this.durationFilter.id_com_brand.forEach(e => {
          urlParams2.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.durationFilter.id_com_location) {
        this.durationFilter.id_com_location.forEach(e => {
          urlParams2.push(`id_com_location[]=${e}`)
        })
      }
      if (this.durationFilter.id_com_department) {
        this.durationFilter.id_com_department.forEach(e => {
          urlParams2.push(`id_com_department[]=${e}`)
        })
      }
      if (this.durationFilter.id_com_feedback_type) {
        this.durationFilter.id_com_feedback_type.forEach(e => {
          urlParams2.push(`id_com_feedback_type[]=${e}`)
        })
      }
      const params = urlParams2.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/requests/durationsPdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  created() {
    this.getCrmSubjects()
    this.getDepartments()
    this.getFeedbackTypes()
    this.getCrmLevels()
    this.getCrmStatuses()
    this.getCrmApplySources()
    this.getBrands()
    this.getLocations()
  },
  methods: {
    getCrmSubjects() {
      this.$store.dispatch('crmSubjects/crm_subjectsList', {
        select: ['com_crm_subject.id AS id', 'com_crm_subject.title AS title'],
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: ['com_department.id AS id', 'com_department.title AS title'],
        where: {
          type: 1,
        },
      })
    },
    getFeedbackTypes() {
      this.$store.dispatch('feedbackTypes/feedback_typesList', {
        select: ['com_feedback_type.id AS id', 'com_feedback_type.title AS title'],
      })
    },
    getCrmLevels() {
      this.$store.dispatch('crmLevels/crm_levelsList', {
        select: ['com_crm_level.id AS id', 'com_crm_level.title AS title'],
      })
    },
    getCrmStatuses() {
      this.$store.dispatch('crmStatuses/crm_statusesList', {
        select: ['com_crm_status.id AS id', 'com_crm_status.title AS title'],
      })
    },
    getCrmApplySources() {
      this.$store.dispatch('crmApplySources/crm_applysourcesList', {
        select: ['com_crm_applysource.id AS id', 'com_crm_applysource.title AS title'],
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    getSummary() {
      this.loading = true
      this.$store.dispatch('requestsReport/summary', {
        id_com_crm_subject: this.id_com_crm_subject,
        id_com_feedback_type: this.id_com_feedback_type,
        id_com_crm_status: this.id_com_crm_status,
        id_com_crm_applysource: this.id_com_crm_applysource,
        id_com_department: this.id_com_department,
        id_com_crm_level: this.id_com_crm_level,
        id_com_brand: this.id_com_brand,
        id_com_location: this.id_com_location,
        sdate: this.sdate,
        edate: this.edate,
      })
    },
    getDurationDetails() {
      this.durationLoading = true
      this.$store.dispatch('requestsReport/detailDurations', {
        id_com_brand: this.durationFilter.id_com_brand,
        id_com_location: this.durationFilter.id_com_location,
        id_com_department: this.durationFilter.id_com_department,
        id_com_feedback_type: this.durationFilter.id_com_feedback_type,
        sdate: this.durationFilter.sdate,
        edate: this.durationFilter.edate,
      })
    },
    showUsersDetail(key, bkey, dkey) {
      this.$store.dispatch('requestsReport/toggleDetailDurationUsers', {
        location: key,
        brand: bkey,
        department: dkey,
      })
    },
    resetPdfData() {
      this.exportPdfFile.status = null
      this.exportPdfFile.folder = null
      this.canvasImageStatus = false
      this.canvasImages = {
        summary: null,
        subjects: null,
        types: null,
        usersSolutionDurations: null,
      }
    },
    async createPdfImages() {
      if (this.exportData.summary) {
        await this.createCanvas('summary')
          .then(canvas => {
            this.canvasImages.summary = canvas.toDataURL('image/jpg', 0.2)
          })
      }
      if (this.exportData.subjects) {
        await this.createCanvas('subjects')
          .then(canvas => {
            this.canvasImages.subjects = canvas.toDataURL('image/jpg', 0.2)
          })
      }
      if (this.exportData.types) {
        await this.createCanvas('types')
          .then(canvas => {
            this.canvasImages.types = canvas.toDataURL('image/jpg', 0.2)
          })
      }
      if (this.exportData.usersSolutionDurations) {
        await this.createCanvas('usersSolutionDurations')
          .then(canvas => {
            this.canvasImages.usersSolutionDurations = canvas.toDataURL('image/jpg', 0.2)
          })
      }
      return this.canvasImages
    },
    async submitPdfImage() {
      this.canvasImageStatus = true
      await this.createPdfImages()
        .then(images => {
          this.$store.dispatch('requestsReport/createPdf', images)
        })
    },
    async createCanvas(element) {
      return html2canvas(document.getElementById(element), {
        allowTaint: true, useCORS: true,
      })
    },
  },
}
</script>
<style scoped></style>
<style lang="scss">

@import '@core/scss/vue/libs/chart-apex.scss';
</style>
