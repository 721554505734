<template>
  <b-card>
    <vue-apex-charts
      v-if="summary.chartOptions"
      type="donut"
      height="500"
      width="100%"
      :options="summary.chartOptions"
      :series="summary.series"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  computed: {
    summary() {
      const data = this.$store.getters['requestsReport/getSummaryChart']
      let chartData = {}
      if (data.series) {
        chartData = {
          series: data.series,
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
            },
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '2rem',
                      fontFamily: 'Montserrat',
                    },
                    value: {
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}%`
                      },
                    },
                    total: {
                      show: false,
                    },
                  },
                },
              },
            },
            labels: data.labels,
            responsive: [
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 380,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
              {
                breakpoint: 576,
                options: {
                  chart: {
                    height: 320,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          name: {
                            fontSize: '1rem',
                          },
                          value: {
                            fontSize: '1rem',
                          },
                          total: {
                            fontSize: '1.5rem',
                          },
                        },
                      },
                    },
                  },
                  legend: {
                    show: true,
                  },
                },
              },
            ],
          },
        }
      }
      return chartData
    },
  },
}
</script>
